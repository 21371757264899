/* eslint-disable */
import { useEffect, useState, useMemo } from 'react';
import { fetchCollectionTypes, fetchCollections, fetchStatistics, fetchDemographics } from '@store/collections';
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from 'antd';
import { Button } from '@components/UI/Buttons';
import { toast } from 'react-toastify';
import API from '@utils/AdminApi';
import DateRange from '@components/Sources/groups/DateRange';
import Targets from '@components/Sources/groups/Targets';
import Demographics from '@components/Sources/groups/Demographics';
import moment from 'moment';
import MultiSelect from '@components/MultiSelect';
import Statistics from '@components/Sources/groups/Statistics';
import Adverts from '@components/Sources/groups/Adverts';

const COLUMNS = {
  adverts: {
    date: "Дата",
    channel: "Канал",
    primetime: "Праймтайм",
    day_of_week: "День недели",
    brand: "Брэнд",
    position: "Позиция",
    program: "Программа",
    day_type: "Тип дня",
    distribution: 'Распространение блока',
    advert_id: "Clip ID",
    orig_dur_in_sec: "Длительность ролика",
    adiss_id: "Clip flight ID",
    // adiss_id_original: "Clip flight ID original",
    orig_pm_stmom: "Время выхода",
    briss_id: "Break Flight ID",
    advertisers_list: "Список рекламодателей",
    brands_list: "Список брэндов",
    subbrands_list: "Список суббрэндов",
    ad_position: "Позиция",
    ad_position_type: "Тип позиции",
    // description: "Описание",
    first_issued_day: "Дата первого выхода",
    note: "Описание рекламы",
    original_adiss_id: "Оригинальный Clip flight ID"
  },

  breaks: {
    date: "Дата",
    channel: "Канал",
    primetime: "Праймтайм",
    day_of_week: "День недели",
    program: "Программа",
    day_type: "Тип дня",
    distribution: 'Распространение блока',
  }
}

import '@components/Sources/Sources.scss';

function Filters({ onFilter }) {
  const [ isPreparing, setIsPreparing ] = useState(false);

  const [ data, setData ] = useState({
    dates_range: null,
    type: 'adverts',
    target_base: [],
    target_groups: [],
    statistics: [],
    columns: [],
    adverts: [],
    grouped: null
  });

  const onChange = (value, field, ch = false) => {

    if (ch) {
      value = value.filter(id => data[field].indexOf(id) == -1);
    }

    const newData = { ...data};
    newData[field] = value;

    if (field === 'type') {
      newData.columns = [];
    }

    setData(newData);
  }

  const clearFilters = () => {
    setData({
      dates_range: null,
      type: 'adverts',
      target_base: [],
      target_groups: [],
      events: [],
      columns: [],
      distribution: [],
      grouped: null
    });
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCollections());
    dispatch(fetchCollectionTypes());
    dispatch(fetchDemographics());
    dispatch(fetchStatistics('adverts'));
  }, []);

  const typeOptions = [
    // {
    // label: 'breaks',
    // value: 'breaks',
  // },
    {
    label: 'adverts',
    value: 'adverts',
  }];

  const groupedOptions = [{
    label: 'Без группировки',
    value: null,
  }, {
    label: 'Группировать: среднее',
    value: 'mean',
  },
    {
      label: 'Группировать: сумма',
      value: 'sum',
    }];

  const prepareFilters = async (excel = false) => {
    setIsPreparing(true);

    if (
      !((data.target_base && data.target_base.length) || (data.target_groups && data.target_groups.length))
    ) {
      setIsPreparing(false);
      toast.error('Базовая или целевая аудитория не выбрана');
      return;
    }

    if (!data.adverts || !data.adverts.length) {
      setIsPreparing(false);
      toast.error('Реклама не выбрана');
      return;
    }

    if (!data.statistics) {
      setIsPreparing(false);
      toast.error('Статистики не выбраны');
      return;
    }

    const eventsData = {
      dates_range: {},
      target_base: null,
      target_groups: [],
      statistics: data.statistics,
      columns: data.columns,
      distribution: data.distribution,
      events: null,
      grouped: data.grouped
    };

    if (data.target_base && data.target_base.length) {
      eventsData.target_base = await API.getCollection(data.target_base[0]).then(response => response.data ? response.data : null);
    }

    if (data.dates_range) {
      eventsData.dates_range.start = moment(data.dates_range.startDate).format('YYYY-MM-DD 05:00:00');
      eventsData.dates_range.end = moment(data.dates_range.endDate).format('YYYY-MM-DD 05:00:00');
    }

    const groupsPromises = [];

    data.target_groups.forEach(row => {
      groupsPromises.push(API.getCollection(row));
    });

    eventsData.target_groups = (await Promise.all(groupsPromises)).map(response => response.data ? response.data : null)

    const advertsPromises = [];

    data.adverts.forEach(row => {
      advertsPromises.push(API.getCollection(row.id));
    });

    const adverts = (await Promise.all(advertsPromises)).map(response => response.data ? response.data : null)

    eventsData.events = adverts?.pop();

    if (data.type == 'adverts') {
      eventsData.events.advert_event = true;
    } else {
      eventsData.events.break_event = true;
    }

    onFilter(eventsData);

    setIsPreparing(false);
  }

  const statisticsValue = useMemo(
    () => (data.statistics ? data.statistics.map(({ type }) => type) : []),
    [ data.statistics ],
  );

  const advertsValue = useMemo(
    () => (data.adverts ? data.adverts.map(({ id }) => id) : []),
    [ data.adverts ]
  );

  const columnsOptions = useMemo(() => {

    if (COLUMNS && COLUMNS[data.type]) {
      const keys = Object.keys(COLUMNS[data.type]);

      return keys.map(value => ({ value, label: COLUMNS[data.type][value]}));
    }

    return [];
  }, [ data.type ]);



  return (
    <div className="sources-form">
      <div className="sources-form-row sources-form-row-title">
        <div>
          <h3>Фильтры выборки</h3>
        </div>
      </div>

      <div className="sources-form-filters">
        <div className="sources-form-filter">
          <DateRange
            label="Период"
            isRequired={false}
            onChange={(value) => onChange(value, 'dates_range')}
            value={data.dates_range}
            className=""
          />
        </div>

        <div className="sources-form-filter">
          <Radio.Group options={typeOptions} onChange={({target: {value}}) => onChange(value, 'type')} value={data.type}/>
        </div>

        <div className="sources-form-filter">
          <Targets
            placeholder="Базовая аудитория"
            className=""
            isRequired={true}
            single={true}
            onChangeBase={(value) => onChange(value, 'target_base')}
            value={data.target_base}
          />
        </div>

        <div className="sources-form-filter">
          <Demographics
            placeholder="Целевая аудитория"
            isRequired={true}
            single={false}
            onChangeBase={(value) => onChange(value, 'target_groups')}
            value={data.target_groups}
          />
        </div>

        <div className="sources-form-filter">
          <Statistics
            name="statistics"
            placeholder="Выбери статистику"
            isRequired={true}
            single={false}
            value={statisticsValue}
            onChange={(value) => onChange(value, 'statistics')}
            source="adverts"
          />
        </div>

        <div className="sources-form-filter">
          <Adverts
            placeholder="Выберите набор фильтров для рекламы"
            isRequired={true}
            single={true}
            value={advertsValue}
            onChange={(value) => onChange(value, 'adverts')}
          />
        </div>

        <div className="sources-form-filter">
          <MultiSelect
            single={false}
            selected={data.columns}
            placeholder="Выберите колонки"
            onChange={value => onChange(value, 'columns')}
            options={columnsOptions}
            line={false}
          />
        </div>
        <div className="sources-form-filter">
          <Radio.Group options={groupedOptions} onChange={({target: {value}}) => onChange(value, 'grouped')} value={data.grouped}/>
        </div>


      </div>

      <div className="sources-form-buttons">
        <div>
          <Button
            onClick={() => prepareFilters()}
            type="primary"
            label="Показать"
          />
        </div>
        <div>
          <Button
            onClick={() => clearFilters()}
            isLoading={isPreparing}
            type="base"
            label="Удалить настройки"
          />
        </div>
      </div>
    </div>
  );
}

export default Filters;
