import React, { useState, useMemo, useRef, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import MultiSelect from "@components/MultiSelect";
import API from '@utils/AdminApi';
import { toast } from "react-toastify";
import { useFormTemplatesProvider } from "./FormTemplatesProvider";
import { OkIcon, CancelIcon, ClearIcon } from "./UI/Buttons";
import { useConfirm } from '@components/Confirm/ConfirmProvider';
import { cleanCollectionTypes, fetchCollections } from "@store/collections";
import { setTemplate } from "@store/forms/FormStorage";

import { getTreeOptions, getParent, findDefaultElement } from "@components/Sources/groups/Utils";

import './FormTemplates.scss';

const newTemplate = { value: '', label: 'Новый шаблон', children: null };

export default function FormTemplates({ type, storage, filters })
{
  const provider = useFormTemplatesProvider();

  const confirm = useConfirm();

  const [ value, setValue ] = useState(null);

  const inited = useRef(false);
  const prevValue = useRef(null);

  const [ changes, setChanges ] =  useState(false);

  const dispatch = useDispatch();

  const types = useSelector((state) => state.collections.collections);

  const stored = useSelector((state) => state.formStorage.templates[storage]);

  const collections = useMemo(() => {
    const data = type ? types.filter(entry => entry.type === type) : [];
    return data.length ? data[0].children : [];
  }, [ types, type ]);

  const options = useMemo(() => {
    return [ newTemplate, ...getTreeOptions(collections) ];
  }, [ collections ]);

  const okActive = useMemo(() => {
    return (value && value.length && changes) || (value && value.length && value[0].value === '') ? true : false;
  }, [ value, changes ]);

  const cancelActive = useMemo(() => {
    return value && value.length && value[0].value != '' && changes ? true : false;
  }, [ value, changes ]);

  const clearActive = useMemo(() => {
    return value && value.length && value[0].value != '' ? true : false;
  }, [ value ]);

  const reset = () => {
    setChanges(false);
    value && value.length && load(value[0].value);

    dispatch(
      setTemplate({
        key: storage,
        value: {
          current: value,
          prev: prevValue.current,
          changes: false,
        }
      })
    );
  }

  const reloadTypes = () => {
    dispatch(
      cleanCollectionTypes()
    );
    
    dispatch(
      fetchCollections()
    );
  }

  const save = useCallback(() => {
    const val = value.length ? value[0] : null;

    if (val === null) {
      return;
    }

    if (val.value === '') {
      API
        .saveCollection({
          name: val.label,
          type: type,
          parent: null,
          content: {
            data: filters,
            union: null
          }
        })
        .then(id => {
          if (id) {
            toast.success('Шаблон создан');
            reloadTypes();

            setValue([{
              value: id,
              label: val.label,
            }]);

            setChanges(false);
          } else {
            toast.error('Ошибка при сохранении шаблона.');
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            toast.error('Ошибка при сохранении шаблона.');
          } else {
            toast.error('Системная ошибка.');
          }
        });
    } else {
      const parent = getParent(collections, val.label);

      API
        .updateCollection({
          id: val.value,
          name: val.label,
          type,
          parent: parent,
          content: {
            data: filters,
            union: null
          }
        })
        .then(id => {
          if (id) {
            toast.success('Шаблон обновлен');
            setChanges(false);
          } else {
            toast.error('Ошибка при сохранении шаблона.');
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            toast.error('Ошибка при сохранении шаблона.');
          } else {
            toast.error('Системная ошибка.');
          }
        });
    }
  }, [ value, collections, filters ]);

  const load = (id) => {
    API
      .getCollection(id)
      .then(response => {
        if (response.data && response.data.content && response.data.content.data) {
          setChanges(false);

          dispatch(
            setTemplate({
              key: storage,
              value: {
                current: value,
                prev: prevValue.current,
                changes: false,
              }
            })
          );

          provider.load(response.data.content.data);
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          setValue([{
            value: el.id,
            label: el.name,
          }]);

        }
      })
      .finally(() => {
        // setIsLoaded(true);
      });
  }

  const deleteCollection = useCallback((event) => {
    const val = value.length ? value[0] : null;

    if (val === null || val.value === '') {
      return;
    }

    confirm.show(
      'Удалить шаблон?',
      () => {
        API
          .deleteCollection(val.value)
          .then(response => {
            reloadTypes();
            setValue(null);
          });
      },
    );

    event.preventDefault();  
  }, [ value, confirm ]);

  const onChange = (selected) => {
    const key = selected && selected.length ? selected[0].value + '|' + selected[0].label : null;

    if (key === null && prevValue.current) {
      provider.load({ channels: [], statistics: [], targetBase: null });
    }
    
    if (prevValue.current !== key) {
      setValue(selected);

      prevValue.current = key;

      if (selected && selected.length && selected[0].value !== '') {
        load(parseInt(selected[0].value));
      }
    }

    dispatch(
      setTemplate({
        key: storage,
        value: {
          current: selected,
          prev: prevValue.current,
          changes: changes,
        }
      })
    );
  }

  useEffect(() => {
    setChanges(true);

    dispatch(
      setTemplate({
        key: storage,
        value: {
          current: value,
          prev: prevValue.current,
          changes: true,
        }
      })
    );
  }, [ filters ]);

  useEffect(() => {
    if (!inited.current) {
      if (types.length) {
        if (stored === null || stored.current === null) {
          const el = findDefaultElement(collections);

          if (el === null) {
            setValue([ newTemplate ]);
          } else {
            setValue([{
              value: el.id,
              label: el.name,
            }]);

            prevValue.current = el.id + '|' + el.name

            load(parseInt(el.id));

            dispatch(
              setTemplate({
                key: storage,
                value: {
                  current: [{
                    value: el.id,
                    label: el.name,
                  }],
                  prev: prevValue.current,
                  changes: false
                }
              })
            );
          }
        } else {
          prevValue.current = stored.prev;
          setValue(stored.current);
          setChanges(stored.changes);
        }

        inited.current = true;
      }
    }
  }, [ collections, types, value, stored ]);

  return (
    <div className="form-template-wrapper">
      <div className="form-control">
        <label>Шаблон</label>
        <MultiSelect
          single={true}
          selected={value}
          dropDown={false}
          placeholder={"Выберите шаблон"}
          onChange={value => onChange(value)}
          options={options}
          path={true}
          line={true}
          editable={true}
        />
      </div>
      <OkIcon disabled={!okActive} onClick={() => okActive && save()} />
      <CancelIcon disabled={!cancelActive} onClick={() => cancelActive && reset() }/>
      <ClearIcon disabled={!clearActive} onClick={(event) => clearActive && deleteCollection(event)} />
    </div>
  );
}