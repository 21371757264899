import { useCallback, useMemo } from 'react';
import Dates from './Dates';
import { useOptions } from './Storage/OptionsProvider';
import { dateFormat } from './Helpers';

function Dropdown({ selected, onSelect, single }) {
  const options = useOptions();

  const isSelected = useCallback(
    (value) => selected?.map(el => el.value)?.indexOf(value.value) > -1,
    [selected]
  );

  const select = (value) => {
    if (!isSelected(value)) {
      single  ? onSelect([value]) : onSelect([...selected, value]);
    }
  }

  const data = useMemo(() => {
    if (options.selectedYear && options.selectedMonth && options.selectedWeek) {
      return options.options[options.selectedYear][options.selectedMonth][options.selectedWeek].sort((a, b) => {
        return a.event_start < b.event_start ? -1 : 1;
      });
    }

    return [];
  }, [ options.selectedYear, options.selectedMonth, options.selectedWeek ]);

  let prevDay = {day: ''};

  if (!options.options) {
    return (
      <div className="showselect-options">
        <div className="showselect-options-option">
          <div>Пусто.</div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Dates />
      <div className="showselect-options">
        { data.map( it => {
          const date = dateFormat(it.event_start, prevDay)

          const className = 'showselect-options-option' + (date[0] !== '' ? ' lined' : '');

          return (
            <div
              className={className}
              key={it.value}
              onClick={() => select(it)}
            >
              <div>
                <span>{ date[0] }</span>
                <span>{ date[1] }</span>
                <span>{ date[2] }</span>
              </div>
              <div>{ it.title }</div>
            </div>
          )
        }) }
      </div>
    </>
  )
}

export default Dropdown;