import React, { useMemo, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ExportButton from '@UI/ExportButton/ExportButton';
import Csv from '@utils/Csv';
import * as XLSX from 'xlsx/xlsx.mjs';
import { toast } from 'react-toastify';

function PieCharts() {

  const { pieCharts } = useSelector((state) => state.audiencePanel);

  const highchartsRefs = [];
  
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());
  highchartsRefs.push(useRef());

  const charts = useMemo(() => {
    return JSON.parse(JSON.stringify(pieCharts)).map(chart => {
      const title = chart.xAxis.categories.length > 0 ? chart.xAxis.categories[0] : '';

      return {
        title,
        options: {
          exporting: {
            fallbackToExportServer: false,
            buttons: {
              contextButton: {
                enabled: false
              },
              printButton: {
                  enabled: false
              },
              exportButton: {
                  enabled: false
              }, 
            }
          },
          chart: {
            type: 'bar',
            height: 200,
            width: 1000
          },
          title: {
            text: '',
            align: 'left'
          },
          xAxis: {
            categories: chart.xAxis.categories,
            labels: {
              enabled: false // disable labels
            }
          },
          accessibility: {
            enabled: false
          },
          yAxis: {
            min: 0,
            title: {
              text: ''
            },
            labels: {
              enabled: false
            },
            gridLineWidth: 0,
          },
          credits: {
            enabled: false,
            text: 'tvmetrica.ru'
          },
          legend: {
            reversed: true,
            layout: 'horizontal',
            align: 'left',
            verticalAlign: 'bottom',
            labelFormatter: function () {
              return `${this.name}, ${this.userOptions.custom}т.`; // Add custom value to legend
            },
          },
          plotOptions: {
            series: {
              stacking: 'normal',
              dataLabels: [{
                enabled: true,
                formatter: function () {
                  return `${this.y}%`; // Custom bar value display
                },
              }
                ]
            }
          },
          series: [ ...chart.series ],
        }
      }
    });
  }, [ pieCharts ]);

  const exportData = useCallback((index, seperator = ';') => {
    if (!pieCharts[index]) {
      return;
    }

    const chartData = pieCharts[index];

    const title = chartData.xAxis.categories.length > 0 ? chartData.xAxis.categories[0] : '';

    if (seperator == 'image') {
      highchartsRefs[index].current.chart.exportChartLocal({}, {
        chart: {
          width: 1000
        }, 
        title: {
          text: title,
        },
      });

      return;
    }

    const rows = [];

    rows.push(seperator ? title : [title]);

    chartData.series.forEach(it => {
      const row = [
        `${it.name}, ${it.custom}т.`,
        it.data[0],
      ];

      rows.push(seperator ? row.join(seperator) : row);
    });
    
    if (seperator) {
      Csv(rows.join("\r\n"), true);
      toast.success(`Данные скопированы в буфер обмена`);
    } else {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(rows);

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Chart');

      XLSX.writeFile(workbook, `pie-charts.xls`);
    }
  }, [ pieCharts ]);

  return (
    <>
      { charts.map((chart, index) => (
        <div key={index} className="pie-chart">
          <div className="chart-title">{ chart.title }</div>
          <ExportButton onClick={sep => exportData(index, sep)} />
          <HighchartsReact highcharts={Highcharts} options={chart.options} allowChartUpdate={true} ref={highchartsRefs[index]} />
        </div>
      )) }
    </>
  )
}

export default PieCharts;
