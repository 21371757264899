import React, { useContext, useEffect, useReducer } from "react"

const OptionsContext = React.createContext();

export const useOptions = () => {
  return useContext(OptionsContext);
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.update }

    case 'year':
      const months = Object.keys(state.options[action.year]);
      const selectedMonth = months[months.length - 1];

      const weeks = Object.keys(state.options[action.year][selectedMonth]);
      const selectedWeek = weeks[weeks.length - 1];

      return { ...state, selectedYear: action.year, months, weeks, selectedMonth, selectedWeek }

    case 'month':

      const _weeks = Object.keys(state.options[state.selectedYear][action.month]);
      const _selectedWeek = _weeks[_weeks.length - 1];

      return { ...state, selectedMonth: action.month, selectedWeek: _selectedWeek, weeks: _weeks }

    case 'week':
      return { ...state, selectedWeek: action.week }
      
    default:
      return state;
  }
}

export const OptionsProvider = ({ children, options }) => {
  const years = options ? Object.keys(options) : [];
  const selectedYear = options ? years[years.length - 1] : null;

  const months = options ? Object.keys(options[selectedYear]) : [];
  const selectedMonth = options ? months[months.length - 1] : null;

  const weeks = options ? Object.keys(options[selectedYear][selectedMonth]) : [];
  const selectedWeek = options ? weeks[weeks.length - 1] : null;

  const [state, dispatch] = useReducer(reducer, {
    options,
    years,
    months,
    weeks,
    selectedYear,
    selectedMonth,
    selectedWeek,
  });

  useEffect(() => {
    dispatch({ type: 'update', update: { options, years, months, weeks, selectedYear, selectedMonth, selectedWeek }});
  }, [ options ]);

  const getOptions = (year, month, week) => {
    try {
      return state.options[year][month][week];
    } catch (e) {

    }

    return [];
  }

  const setYear = (year) => {
    dispatch({ type: 'year', year });
  }

  const setMonth = (month) => {
    dispatch({ type: 'month', month });
  }

  const setWeek = (week) => {
    dispatch({ type: 'week', week });
  }

  return (
    <OptionsContext.Provider value={{
      options: state.options,
      years: state.years,
      months: state.months,
      weeks: state.weeks,
      selectedYear: state.selectedYear,
      selectedMonth: state.selectedMonth,
      selectedWeek: state.selectedWeek,
      getOptions,
      setYear,
      setMonth,
      setWeek
    }}>
      { children }
    </OptionsContext.Provider>
  )
}