import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {fetchChannels, fetchCollections } from '@store/collections';
import { Button } from '@components/UI/Buttons';
import API from '@utils/AdminApi';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from "react-router-dom";
import { setEventsAudience } from '../../store/forms/FormStorage';
import moment from 'moment-timezone';
import ExportButton from '@UI/ExportButton/ExportButton';
import Csv from '@utils/Csv';
import * as XLSX from 'xlsx/xlsx.mjs';
import { toast } from 'react-toastify';

import { ReactComponent as Right } from '../collections/crud/UI/images/right.svg';
import { ReactComponent as Down } from '../collections/crud/UI/images/down.svg';

import FormTemplates from '@components/FormTemplates/FormTemplates';
import { FormTemplatesProvider } from '@components/FormTemplates/FormTemplatesProvider';
import { ConfirmProvider } from '@components/Confirm/ConfirmProvider';
import Confirm from '@components/Confirm/Confirm';

import './CompareAudience.scss';
import { useAuthContext } from "@store/AuthProvider";
import CompareAudienceFilters from './CompareAudienceFilters';

function CompareAudience() {
  const dispatch = useDispatch();

  const eventsAudience = useSelector((state) => state.formStorage.eventsAudience);

  let navigate = useNavigate();

  const highchartsRef = useRef();

  const [ isOpen, setIsOpen ] = useState(true);

  const [ isLoading, setIsLoading ] = useState(false);

  const [ chartData, setChartData ] = useState(null);

  const [ filters, setFilters ] = useState({});
  const [ seletedFilters, setSelectedFilters ] = useState({});

  const { population: population_100 } = useAuthContext();

  const toggle = () => {
    setIsOpen(value => !value);
  }

  useEffect(() => {
    dispatch(
      fetchChannels()
    );

    dispatch(
      fetchCollections()
    );
  }, [ ]);

  const updateFilters = (filters, loaded = false) => {
    setSelectedFilters(filters);
    dispatch(setEventsAudience(filters));

    if (!loaded) {
      setFilters(filters);
    }
  }

  const onChartData = (chartData) => {
    setChartData(chartData);
    dispatch(setEventsAudience({ ...eventsAudience, chartData }));
  }

  const exportData = useCallback((seperator = ';') => {
    if (!chartData) {
      return;
    }

    if (seperator == 'image') {
      highchartsRef.current.chart.exportChartLocal({});

      return;
    }

    const rows = [];

    rows.push(seperator ? chartData.title.text : [chartData.title.text]);
    rows.push(seperator ? chartData.subtitle.text : [chartData.subtitle.text]);
    rows.push(seperator ? "" : [""]);

    const header = [
      seperator ? '""' : '',
      ...chartData.series.map(value => value.name)
    ];

    rows.push(seperator ? header.join(seperator) : header);

    chartData.xAxis.categories.forEach((category, index) => {
      const row = [
        seperator ? `"${category}"` : category,
        ...chartData.series.map(value => value.data[index].y ?? '')
      ];

      rows.push(seperator ? row.join(seperator) : row);
    });

    if (seperator) {
      Csv(rows.join("\r\n"), true);
      toast.success(`Данные скопированы в буфер обмена`);
    } else {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(rows);

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Chart');

      XLSX.writeFile(workbook, "audience-compare.xls");
    }
  }, [ chartData ]);

  const addLabels = (series) => {
    if (! series) {return series;}
    return series.map((s) => ({
      ...s,
      dataLabels: {
        enabled: true,
        formatter: function () {
          return `${this.y}т. [Sample: ${this.point.custom.sample_size}]`;
        },
      },
    }));
  };

  const chartOptions = useMemo(() => (chartData ? {
    exporting: {
      fallbackToExportServer: false,
      buttons: {
        contextButton: {
          enabled: false
        },
        printButton: {
            enabled: false
        },
        exportButton: {
            enabled: false
        }, 
      }
    },
    chart: {
      type: 'column',
      height: 600,
      width: 1000,
    },
    title: {
      text: chartData.title ? chartData.title.text : ''
    },
    subtitle: {
      text: chartData.subtitle ? chartData.subtitle.text : '',
      align: 'center',
      verticalAlign: 'bottom',
      y: 10,
    },
    xAxis: {
      ...chartData.xAxis,
      gridLineWidth: 0
    },
    legend: {
      enabled: true,
      align: 'center',
      shadow: false,
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Аудитория (тыс) - Qual.Reach',
        enabled: false
      },
      labels: {
        enabled: false, // Remove Y-axis values
      },
      gridLineWidth: 0,
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: ( // theme
            Highcharts.defaultOptions.title.style &&
            Highcharts.defaultOptions.title.style.color
          ) || 'gray',
          textOutline: 'none'
        },
        formatter: function (a) {
          if (chartData.series) {
            let x = this.x;
            let sample_size = _.reduce(chartData.series, function(result, s_value, key) {return result + s_value.data[x].custom.sample_size;}, 0);
            return `${this.total} [Sample: ${sample_size}]`;
          }
          return `${this.total}`;
        },
      }
    },
    tooltip: {
      enabled: false
    },
    credits: {
      text: 'tvmetrica.ru'
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: [{
          enabled: true,
          formatter: function () {
            return `${this.series.name}`; // Custom bar value display
          },
          align: 'left',
          x: 165,
        },
          {
            enabled: true,
            formatter: function () {
              return `${Math.round(this.percentage)}% ${this.y}т. [${this.point.custom.sample_size}]`; // Custom bar value display
            },
            style: {
              color: 'black'
            }
          },
        ],
        events: {
          click: (e) => {
              navigate('/panel/audience',
                  {state: {
                  sample: e.point.custom.sample,
                  sample_size: e.point.custom.sample_size,
                  source: e.point.custom.source,
                  name: e.point.series.name,
                  method: chartData.method
                }}
              );
          }
        }}
    },
    series: addLabels(chartData.series) ?? {},
  } : {}), [ chartData ]);

  const compare = async () => {
    let base = [];

    setIsLoading(true)

    if (seletedFilters.target) {
      base = await API.getCollection(seletedFilters.target.id);
      base = base.data;
    }

    const _payloadX = { ...seletedFilters.payloadX };
    const _payloadY = { ...seletedFilters.payloadY };

    if (_payloadX.issued) {
      _payloadX.issued = _payloadX.issued.map(item => item.value);
    }

    if (_payloadY.issued) {
      _payloadY.issued = _payloadY.issued.map(item => item.value);
    }

    // INFO: Get collections body

    let payload = {
      qual_reach: seletedFilters.groups,
      target_base: base,
      method: seletedFilters.method,
      population_100: population_100,
      events: [ { ..._payloadX }, { ..._payloadY} ]
    };

    for (let event of payload.events) {
      delete event.source;
      delete event.filter;

      if (event.timeband) {
        event.timeband = await API.getCollection(event.timeband.id);
        event.timeband = event.timeband.data;
      }

      if (event.date) {
        event.date = moment(event.date).format('YYYY-MM-DD') + ' 05:00:00';
        event.dates_range = {"start": event.date, "end": event.date}
        delete event.date
      }

      if (event.premier) {
        event.premier = {'all': null, 'premier': true, 'repeat' : false}[event.premier]
      }

      if (event.channel) {
        event.channels = event.channel;
        delete event.channel;
      }

      if (event.program) {
        event.programs = event.program;
        delete event.program;
      }

      if (event.issued) {
        event.issued_ids = event.issued;
        delete event.issued;
      }
    }

    API
      .postCompare(payload)
      .then((data) => {
        onChartData({ ...data.data.chart, method: seletedFilters.method });
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <FormTemplatesProvider>
      <div className="compare-audience">
        <div className="compare-audience-wrapper">
          <div className="compare-audience-title">
            <div className="list" onClick={() => toggle()}>{ isOpen ? <Down /> : <Right /> }</div>
            <div className="title" onClick={() => toggle()}>Сравнение аудиторий</div>
            <div className="template">
              <ConfirmProvider>
                <Confirm />
                <FormTemplates type={8} filters={filters} storage="eventsAudience" />
              </ConfirmProvider>
            </div>
            <div></div>
          </div>
          <div className={`compare-audience-filters${isOpen ? "" : " hidden"}`}>
            <CompareAudienceFilters updateFilters={updateFilters} setChartData={setChartData} />

            <div className="buttons">
              <Button
                onClick={() => compare()}
                disabled={false}
                type="primary"
                label="Сравнить"
              />
            </div>
          </div>
        </div>
        {!isLoading && !!chartData &&
          <div className="compare-audience-chart">
            <ExportButton onClick={sep => exportData(sep)} />
            <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={highchartsRef} allowChartUpdate={true}/>
          </div>
        }
      </div>
    </FormTemplatesProvider>
  );
}

export default CompareAudience;
