import { useState, useMemo, useEffect, useRef } from "react";
import SelectBox from "@UI/SelectBox";
import { months } from "@components/CompositionUI/UI/MonthPicker";
import { useOptions } from './Storage/OptionsProvider';

function Dates() {
  const options = useOptions();

  const yearOptions = useMemo(() => {
    return options.years.map( it => ({
      label: it,
      value: it,
    }));
  }, [ options.months ]);

  const monthOptions = useMemo(() => {
    return months.filter( it => options.months.indexOf(`${it.value}`) !== -1 ).map( it => ({ ...it, value: `${it.value }`}));
  }, [ options.months ]);

  const weekOptions = useMemo(() => {
    return options.weeks.map( it => ({
      label: it,
      value: it,
    }));
  }, [ options.weeks ]);

  return (
    <div className="showselect-dates-wrapper">
      <div className="year-wrapper">
        <SelectBox
          value={options.selectedYear}
          placeholder="Год"
          options={yearOptions}
          onChange={item => 'value' in item && options.setYear(item.value)}
        />
      </div>

      <div className="month-wrapper">
        <SelectBox
          value={options.selectedMonth}
          placeholder="Месяц"
          options={monthOptions}
          onChange={item => 'value' in item && options.setMonth(item.value)}
        />
      </div>

      <div className="week-wrapper">
        <SelectBox
          value={options.selectedWeek}
          placeholder="Неделя"
          options={weekOptions}
          onChange={item => 'value' in item && options.setWeek(item.value)}
        />
      </div>
    </div>
  );
}

export default Dates;