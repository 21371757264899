/* eslint-disable */
import React from 'react';

import Placeholder from './Placeholder';
import Dropdown from './Dropdown';
import Selector from './Selector';
import { OptionsProvider } from './Storage/OptionsProvider';

export default class ShowsSelect extends React.Component {
  constructor(props) {
    super(props);

    this._id = Math.round(Math.random() * 10000);

    this.state = {
      selected: [],
      selectedValues: [],
      options: props.options ?? {},
      dropdown: false,
    };

    this.wrapperRef = React.createRef();

    this.timer = React.createRef(null);
  }

  componentDidMount() {
    document.addEventListener('mousedown', (event) =>
      this.handleClickOutside(event),
    );

    this.select(this.props.selected ?? []);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', (event) =>
      this.handleClickOutside(event),
    );
  }

  componentDidUpdate(prevProps) {
    if (!this.compareArrays(this.props.selected, prevProps.selected)) {
      this.select(this.props.selected ?? []);
    }

    if (!this.compareArrays(this.props.options, prevProps.options)) {
      this.setState((state) => {
        state.options = this.props.options;

        return state;
      });

      this.select(this.props.selected ?? []);
    }
  }

  compareArrays(array1, array2) {
    return JSON.stringify(array1) === JSON.stringify(array2);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
      if (this.state.dropdown) {
        this.toggleDropdown(event);

        if (this.props.onBlur) {
          this.props.onBlur(this.state.selectedValues);
        }
      }
    }
  }

  toggleDropdown(event) {
    if (!event.nativeEvent || event.nativeEvent?.defaultPrevented === false) {
      this.setState((state) => {
        state.dropdown = !state.dropdown;

        if (this.props.onToggleDropdown) {
          this.props.onToggleDropdown(state.dropdown);
        }

        return state;
      });
    }
  }

  select(values) {
    this.setState((state) => {
      state.selected = values;

      // Сохранять исходные значения выбранных values для передачи их в handleClickOutside
      state.selectedValues = values;

      if (!!this.props.single && state.dropdown) {
        state.dropdown = false;
      }

      return state;
    });
  }

  onSelect(values) {
    if (this.props.readonly) {
      return;
    }

    this.select([ ...values ]);

    if (this.props.onChange) {
      this.props.onChange(
        values,
      );
    }
  }

  handleDeleteItem(values) {
    if (this.props.onBlur) {
      this.props.onBlur(values.map((el) => el.value));
    }
  }

  render() {
    const className =
      'showselect-wrapper ' + (this.props.line ? 'one-line' : '');

    return (
      <div className="showselect" ref={this.wrapperRef}>
        <OptionsProvider
          options={this.props.options}
        >
          <div
            className={className}
            onClick={(event) => this.toggleDropdown(event)}
          >
            {this.state.selected.length ? (
              <Selector
                selected={this.state.selected}
                onSelect={(values) => this.onSelect(values)}
                onTrashClick={(values) => this.handleDeleteItem(values)}
                dropDown={!!this.props.dropDown && !this.props.single}
                editable={this.props.editable && this.props.single}
                onEdit={(value) => this.editSelected(value)}
              ></Selector>
            ) : (
              <Placeholder
                placeholder={this.props.placeholder ?? 'Выбрать...'}
                oneLine={this.props.line}
              />
            )}
          </div>
          {!this.props.readonly && !!this.state.dropdown && (
            <div className="showselect-dropdown">
              {!!this.props.search && (
                <Search
                  filter={this.state.tempFilter}
                  onFilter={(filter) => this.setFilter(filter)}
                ></Search>
              )}
              <Dropdown
                selected={this.state.selected ?? []}
                onSelect={(values) => this.onSelect(values)}
                single={!!this.props.single}
                filter={this.state.filter}
              ></Dropdown>
            </div>
          )}
        </OptionsProvider>
      </div>
    );
  }
}
