import { ReactComponent as Dropdown } from '../CompositionUI/UI/images/dropdown-g.svg';
import { useMemo } from 'react';
import Select, { components } from 'react-select';

const selectStyles = {
  control: (base, state) => ({
    ...base,
    background: '#EBF0F6',
    borderRadius: 4,
    border: null,
    borderWidth: 0,
    boxShadow: null,
    height: '28px',
    minHeight: '28px',
    padding: '0',
    fontFamily: "'Roboto', sans-serif",
    fontSize: '16px',
    letterSpacing: '0.1px',
    color: '#585771',
    boxSizing: 'border-box',
    minWidth: "100%"
  }),
  option: (base, state) => ({
    ...base,
    padding: '2px 8px',
    fontFamily: "'Roboto', sans-serif",
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: '#585771',
    backgroundColor: state.isSelected ? '#EBEFF6' : '#FFFFFF',
    cursor: 'pointer',
  }),
  placeholder: (base, state) => ({
    ...base,
    color: '#585771',
    margin: 0,
  }),
  valueContainer: (base, state) => ({
    ...base,
    padding: '0 0 0 8px',
  }),
  input: (base, state) => ({
    ...base,
    padding: 0,
    margin: 0,
    fontFamily: "'Roboto', sans-serif",
    fontSize: '16px',
    letterSpacing: '0.1px',
    color: '#585771',
  }),
  singleValue: (base, state) => ({
    ...base,
    fontFamily: "'Roboto', sans-serif",
    fontSize: '16px',
    letterSpacing: '0.1px',
    color: '#585771',
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '28px',
  }),
  menu: (provided, state) => ({
    ...provided,
    background: '#FFFFFF',
    borderRadius: 4,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    whiteSpace: 'nowrap',
    width: 'max-content',
    minWidth: '100%',
  }),
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Dropdown />
    </components.DropdownIndicator>
  );
}

function SelectBox(props) {
  const value = useMemo(() => {
    const label = props?.options?.filter(option => option.value === props?.value);

    return {
      value: props?.value,
      label: label?.length ? label[0].label : ''
    }
  }, [ props ]);

  return (
    <Select
      styles={selectStyles}
      components={{ DropdownIndicator }}
      isSearchable={false}
      isClearable={false}
      placeholder="Выбрать"
      noOptionsMessage={() => 'Поле не найдено'}
      {...props}
      value={value}
    ></Select>
  );
}


export default SelectBox;