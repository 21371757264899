import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Formik, Form, Field, useField } from 'formik';
import { FormLabel, FormControl } from '@chakra-ui/react'
import LoaderTop from '@components/LoaderTop/LoaderTop';
import { fetchCollectionTypes, fetchGroups, cleanCollections, cleanCollectionTypes } from '@store/collections';
import { useDispatch, useSelector } from 'react-redux';
import Composition from '@components/CompositionUI/Composition';
import { BackButton, SaveButton } from '@components/CompositionUI/UI/Buttons';
import { toast } from "react-toastify";
import API from '@utils/AdminApi';
import { TreeSelect } from 'antd';
import { AudienceSampleProvider } from '@components/AudienceSample/AudienceSampleProvider';

import './CollectionEdit.scss';

const { TreeNode } = TreeSelect;

const treeOptions = (options) => {
  return options.map(option => (
    <TreeNode value={option.id ?? 0} title={option.name} key={option.id ?? 0}>
      { option.children ? treeOptions(option.children) : null }
    </TreeNode>
  ));
}

/**
 * форма создани и редактирования набора
 * @param {
 *  id?,
 *  parent,
 *  type,
 * } props 
 * @returns 
 */
function CollectionEdit(props) {
  const targets = useSelector((state) => state.collections.types);
  const groups = useSelector((state) => state.collections.groups);

  const type = useRef(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultValues, setdefaultValues] = useState({
    name: '',
    type: 0,
    parent: null,
    content: {},
  });

  const [compositionData, setCompositionData] = useState();

  const dispatch = useDispatch();

  const typeGroups = useMemo(() => {
    return groups.filter(group => group.type === type.current);
  }, [ groups ])

  /**
   * иницализация формы, ожидает загрузку списка типов
   */
  useEffect(() => {
    let loaded = true;

    if (!targets || !targets.length) {
      dispatch(fetchCollectionTypes());

      loaded = false;
    }

    if (!groups || !groups.length) {
      dispatch(fetchGroups());

      loaded = false;
    }

    if (!loaded) {
      return;
    }

    if (props.id) {
      API
        .getCollection(props.id)
        .then(response => {
          if (response.data) {
            response.data.parent = response.data?.parent ?? null;

            setdefaultValues(response.data);

            setCompositionData([{
              type: response.data?.type,
              data: response.data?.content
            }]);

            type.current = response.data?.type;
          }
        })
        .finally(() => {
          setIsLoaded(true);
        });
    } else {
      setdefaultValues({
        name: defaultValues.name,
        parent: props.parent ?? null,
        type: props.type,
        content: defaultValues.content,
        is_default: false,
      });

      type.current = props.type;

      setIsLoaded(true);
    }
  }, [ props.id, targets, groups ]);

  /**
   * создание или обновление набора
   * @param {*} values 
   */
  const save = (values) => {
    dispatch(cleanCollections());

    if (props.id) {
      API
        .updateCollection({
          id: props.id,
          name: values.name,
          type: type.current,
          parent: defaultValues.parent,
          content: values.content,
          is_default: values.is_default,
        })
        .then(id => {
          if (id) {
            toast.success('Набор обновлен');
            props.onListHandle();
          } else {
            toast.error('Ошибка при обновлении набора.');
          }

          dispatch(
            cleanCollectionTypes()
          );
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            toast.error('Ошибка при сохранении набора.');
          } else {
            toast.error('Системная ошибка.');
          }
        });
    } else {
      API
        .saveCollection({
          name: values.name,
          type: type.current,
          parent: defaultValues.parent,
          content: values.content,
          is_default: values.is_default,
        })
        .then(id => {
          if (id) {
            toast.success('Набор создан');
            props.onListHandle();
          } else {
            toast.error('Ошибка при сохранении набора.');
          }

          dispatch(
            cleanCollectionTypes()
          );
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            toast.error('Ошибка при сохранении набора.');
          } else {
            toast.error('Системная ошибка.');
          }
        });
    }
  }

  /**
   * обертка для поля выбора условий
   * @returns 
   */
  const CompositionField = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props);

    return (
      <FormControl isRequired={true} isInvalid={meta.errors && meta.touched}>
        <FormLabel>{label}</FormLabel>
        <input {...field} {...props} />

        { targets.map(option => (
          <AudienceSampleProvider key={`collection-${option.id}`} available={option.id == 1}>
            <Composition
              onChange={(data) => helpers.setValue(data)}
              selected={type.current}
              type ={option.id}
              prefix={`collection-${option.id}`}
              data={compositionData}
            />
          </AudienceSampleProvider>
        ))}
      </FormControl>
    );
  };

  return isLoaded ? (
    <Formik
      enableReinitialize={true}
      initialValues={defaultValues}
      onSubmit={(values, actions) => {
        save(values);
      }}
    >
      {() => (
        <Form>
          <div className="collections-form">
            <div className="form-header">
              { props.id ? 'Редактирование' : 'Создание' } набора
            </div>

            <div className="form-content">
              <div className="two-columns">
                <Field name="name">
                  {({ field, form }) => (
                    <FormControl className="input-border" isRequired={true} isInvalid={form.errors.name && form.touched.name}>
                      <FormLabel htmlFor="name">Название</FormLabel>
                      <input {...field} id="name" />
                    </FormControl>
                  )}
                </Field>

                <Field name="type">
                  {({ field, form }) => (
                    <FormControl className="input-border" isRequired={true} isInvalid={form.errors.type && form.touched.type}>
                      <FormLabel htmlFor="type">Тип</FormLabel>
                      <div className="text">
                        <input type="hidden" {...field} id="type" />
                        { targets.length ? targets.filter(entry => entry.id === type.current).pop().name : <></> }
                      </div>
                    </FormControl>
                  )}
                </Field>
              </div>

              { defaultValues.type == 5 || defaultValues.type == 8 ? (
                <div>
                  <Field name="is_default">
                    {({ field, form }) => (
                      <FormControl className="checkbox-item" isInvalid={form.errors.type && form.touched.type}>
                        <input type="checkbox" {...field} id="is_default" checked={field.value == true} />
                        <FormLabel htmlFor="is_default">Набор по умолчанию</FormLabel>
                      </FormControl>
                    )}
                  </Field>
                </div>
              ) : <></> }

              { defaultValues.type != 8 ? (
                <div className="composition-field">
                  <CompositionField label="Условие выборки набора" type="hidden" name="content" />
                </div>
              ) : <></> }

            </div>
          </div>

          <div className="two-columns collections-form-buttons">
            <div>
              <BackButton onClick={() => props.onListHandle()}></BackButton>
            </div>
            <div>
              <SaveButton></SaveButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  ) : ( <LoaderTop /> );
}

export default CollectionEdit;