import moment from 'moment';

const weekDays = ['', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

export const dateFormat = (dateString, prevDay) => {
  const _date = dateString.split(' ');

  const date = moment(`${_date[0]}T00:00:00`);

  const _prevDay = prevDay.day;

  // eslint-disable-next-line no-param-reassign
  prevDay.day = date.format('D');

  return [
    prevDay.day != _prevDay ? date.format('D') : '',
    prevDay.day != _prevDay ? weekDays[date.isoWeekday()] : '',
    _date[1],
    date.format('DD.MM.YYYY')
  ];
}

export const dateFormatString = (dateString) => {
  if (!dateString) {
    return '';
  }

  let prevDay = { day: '' };

  const date = dateFormat(dateString, prevDay);

  return date[0] + ' ' + date[1];
}

export const dateFullFormatString = (dateString) => {
  if (!dateString) {
    return '';
  }

  let prevDay = { day: '' };

  const date = dateFormat(dateString, prevDay);

  return date[3] + ' ' + date[1] + ' ' + date[2];
}
