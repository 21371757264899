import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import API from '@utils/AdminApi';
import { fetchChannels, fetchChannelsCollections, fetchCollections, fetchDemographicsBreakdown } from '@store/collections';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Demographics from './UI/Demographics';
import { useDispatch, useSelector } from 'react-redux';
import { setBarData, setQueryData, setDemographicsData, setPieCharts } from '@store/audiencePanel';
import MultiSelect from '../../components/MultiSelect';
import PieCharts from './UI/PieCharts';
import ExportButton from '@UI/ExportButton/ExportButton';
import Csv from '@utils/Csv';
import * as XLSX from 'xlsx/xlsx.mjs';
import { toast } from 'react-toastify';

import './Audience.scss';

function Audience() {
  const location = useLocation();
  const dispatch = useDispatch();

  const audBarStatus = useRef({
    loaded: 0,
    demographics: []
  });

  // const [ audDemTable, setAudDemTable ] = useState({ columns: [], data: [] });

  const { barData, queryData, demographicsData } = useSelector((state) => state.audiencePanel);

  const { demographicsBreakdown: demographics } = useSelector((state) => state.collections);

  const highchartsRef = useRef();

  const audBar = useMemo(() => ({
    exporting: {
      fallbackToExportServer: false,
      buttons: {
        contextButton: {
          enabled: false
        },
        printButton: {
            enabled: false
        },
        exportButton: {
            enabled: false
        }, 
      }
    },
    chart: {
      type: 'pie'
    },
    title: {
      text: null,
    },
    subtitle: {
      text: `Целевая аудитория: ${location.state.source.description}<br/>Метод: ${location.state.method}. Sample [${location.state.sample_size}]: ${location.state.source.audience_name}, группа ${location.state.source.group_name}`,
      align: 'center',
      verticalAlign: 'bottom'
    },
    accessibility: {
      enabled: false
    },

    colors: ['#585771', '#D9E0ED'],
    xAxis: { ...barData.xAxis },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        formatter: function() {
          return this.value;
        },
      },
      accessibility: {
        description: 'Аудитория в тысячах',
        rangeDescription: 'тысяч',
      },
      opposite: true,
    },
    tooltip: {
      enabled: false,
    },
    credits: {
      text: 'tvmetrica.ru'
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        cursor: 'pointer',
        dataLabels: [{
          enabled: true,
          formatter: function () {
            return `${this.y}%`; // Custom bar value display
          },
          distance: -30
        },
          {
            enabled: true,
            formatter: function () {
              return `${this.key},   ${this.point.options.custom}т.`; // Custom bar value display
            },
            distance: 30
          }
        ]
      }
    },

    series: [ ...barData.series ],
  }), [ barData ]);

  const exportData = useCallback((seperator = ';') => {
    if (!barData) {
      return;
    }

    if (seperator == 'image') {
      highchartsRef.current.chart.exportChartLocal({}, {
        chart: {
          width: 1000
        },
        title: {
          text: 'Демография аудитории',
        },
      });

      return;
    }

    const rows = [];

    rows.push(seperator ? 'Демография аудитории' : ['Демография аудитории']);

    barData.series[0].data.forEach(it => {
      const row = [
        `${it.name}, ${it.custom}т.`,
        `${it.y}%`,
      ];

      rows.push(seperator ? row.join(seperator) : row);
    });
    
    if (seperator) {
      Csv(rows.join("\r\n"), true);
      toast.success(`Данные скопированы в буфер обмена`);
    } else {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(rows);

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Chart');

      XLSX.writeFile(workbook, `audience.xls`);
    }
  }, [ barData ]);

  const members = useMemo(() => {
    return location.state ? location.state.members : JSON.parse('');
  }, [location]);

  const demographicOptions = useMemo(
    () => demographics
      ? demographics.map(({ id, name }) => ({ label: name, value: id }))
      : [],
    [ demographics ]
  );

  const demographicsValue = useMemo(
    () => demographics && demographicsData
      ? demographics.filter(({ id }) => demographicsData.indexOf(id) !== -1).map(({ id , name}) => ({ id, name }))
      : [],
    [ demographicsData, demographics ]
  );

  const onChange = (value) => {
    dispatch(setDemographicsData(value));
  }

  const isChanged = useCallback(() => {
    if (audBarStatus.current.demographics.length === 0 && demographicsData.length === 0) {
      return false;
    }

    if (audBarStatus.current.demographics.length !== demographicsData.length) {
      return true;
    }

    return !!audBarStatus.current.demographics.filter(({ id }) => demographicsData.indexOf(id) === -1).length

  }, [ demographicsData ]);

  useEffect(() => {
/*
    location.state = {
      source: JSON.parse('{"group":"G90","base":[],"name":"Аудитория Х","SelectedX":[{"value":"1862;1;1;195350","label":"Лесник","path":["НТВ"]}],"issuedSelectedX":["f7152fce"]}'),
      members: JSON.parse('["1beaac7","1bef959","1befb4f","1befd2a","1bf103c","1bf19ca","1bf1efc","1bf2769","1bf2c77","1bf2c78","1bf3280","1bf3a01","1bf3a02"]'),
    };
*/
    if (audBarStatus.current.loaded === 0 || isChanged()) {
      audBarStatus.current.loaded = 1;
      audBarStatus.current.demographics = demographicsValue;

      API.postAudienceDemographics(location.state.source, location.state.sample, demographicsValue).then((response) => {
        let newQueryData = { ...queryData };

        newQueryData.fromDate = moment(response.minDate).toDate();
        newQueryData.toDate = moment(response.maxDate).toDate();

        dispatch(setQueryData(newQueryData));
        if (barData.series.length === 0) {
          dispatch(setBarData({
            series: response.sex_age_chart.series
          }));
        }

        dispatch(setPieCharts(response.pie_charts ?? []));

        // setAudDemTable(response.table);
        }, (error) => {
        audBarStatus.current.loaded = 0;
        dispatch(setPieCharts([]));
        // setAudDemTable({ columns: [], data: [] });
      });

      dispatch(
        fetchChannelsCollections(),
      );

      dispatch(
        fetchCollections(),
      );

      dispatch(
        fetchDemographicsBreakdown()
      );

      dispatch(
        fetchChannels(),
      );
    }
  }, [ location, demographicsValue, queryData ]);

  return (
    <>
      <div className="audience-wrapper">
        <div className="audience-title">
          <div className="title">
            <div>Демография аудитории</div>
            {/* <div className="subtitle">Целевая аудитория: {location.state.source.description}</div> */}
            {/* <div> Метод: {location.state.method}. Sample [{location.state.sample_size}]: {location.state.source.audience_name}, группа {location.state.source.group_name}</div> */}
          </div>
          <div>
            <div className="form-control">
              <label>Категории</label>

              <MultiSelect
                options={demographicOptions}
                onChange={onChange}
                search={false}
                selected={demographicsData}
                single={false}
                line={true}
              />
            </div>
          </div>
        </div>
        <div className="audience-content">
          <div className="bars">
            <ExportButton onClick={sep => exportData(sep)} />
            <HighchartsReact highcharts={Highcharts} options={audBar} ref={highchartsRef} allowChartUpdate={true} />
          </div>
          <div className="pie-charts">
            <PieCharts />
          </div>
        </div>
      </div>
      <br />
      <Demographics members={members} />
    </>
  );
}

export default Audience;

