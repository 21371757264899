import { Radio } from 'antd';
import { useState, useEffect, useMemo, createRef, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useFormTemplatesProvider } from '@components/FormTemplates/FormTemplatesProvider';

import EventsAudience from '@components/EventsAudience/EventsAudience';
import Targets from '../../components/Sources/groups/Targets';

const methodOptions = [
  {
    label: 'Факт',
    value: 'fact',
  },
  {
    label: 'Соцдем',
    value: 'demography',
  },
  {
    label: 'Профиль',
    value: 'profile',
  },
];

const compare = (a, b) => {
  for (const key in a) {
    if (JSON.stringify(a[key]) != JSON.stringify(b[key])) {
      return false;
    }
  }

  return true;
}

function CompareAudienceFilters({ updateFilters, setChartData }) {

  const eventsAudience = useSelector((state) => state.formStorage.eventsAudience);

  const tmplProvider = useFormTemplatesProvider();

  const [ qualReach, setQualReach ] = useState({ g0: '50', g1: '75', g2: '90' });

  const [ method, setMethod ] = useState('fact');

  const [ target, setTarget ] = useState();

  const [ payloadX, setPayloadX ] = useState();

  const [ payloadY, setPayloadY ] = useState();

  const targetValue = useMemo(() => target ? [ target.id ] : null, [ target ]);

  const _payloadX = useRef(null);
  const _payloadY = useRef(null);

  const payloadXRef = createRef();
  const payloadYRef = createRef();

  const onChange = (num, payload) => {
    switch (num) {
      case 1:
        setPayloadX(payload);

        if (!compare(_payloadX.current, payload)) {
          updateFilters({ ...eventsAudience, payloadX: payload });
        }

        _payloadX.current = payload;
        break;

      case 2:
        setPayloadY(payload);

        if (!compare(_payloadY.current, payload)) {
          updateFilters({ ...eventsAudience, payloadY: payload });
        }
        _payloadY.current = payload;
        break;

      default:
        break;
    }
  };

  const onMethodChange = (value) => {
    setMethod(value);
    updateFilters({ ...eventsAudience, method: value });
  };

  const onGroupValueChange = (payload) => {
    setQualReach(payload);
    updateFilters({ ...eventsAudience, groups: payload});
  };

  const onTargetChange = (target) => {
    setTarget(target);
    updateFilters({ ...eventsAudience, target });
  };

  useEffect(() => {
    const init = {
      groups: eventsAudience.groups ?? { g0: '50', g1: '75', g2: '90' },
      method: eventsAudience.method ?? 'fact',
      target: eventsAudience.target ?? null,
      payloadX: eventsAudience.payloadX ?? null,
      payloadY: eventsAudience.payloadY ?? null,
      chartData: eventsAudience.chartData ?? {},
    };

    setQualReach(init.groups);
    setMethod(init.method);
    setTarget(init.target);
    setPayloadX(init.payloadX);
    setPayloadY(init.payloadY);
    setChartData(init.chartData);

    updateFilters(init);
  }, [ ]);

  useEffect(() => {
    if (tmplProvider.filters !== null) {
      const filters = { ...tmplProvider.filters };

      setPayloadX(filters.payloadX);
      setPayloadY(filters.payloadY);
      setTarget(filters.target);
      setQualReach(filters.groups);
      setMethod(filters.method);
      setChartData(null);

      _payloadX.current = filters.payloadX;
      _payloadY.current = filters.payloadY;

      payloadXRef.current.setPayload(filters.payloadX);
      payloadYRef.current.setPayload(filters.payloadY);

      updateFilters({ ...eventsAudience, ...tmplProvider.filters }, true);

      tmplProvider.reset();
    }
  }, [ tmplProvider.filters ]);

  return (
    <>
      <Targets
        name="targetBase"
        value={targetValue}
        onChange={onTargetChange}
        label="Целевая аудитория"
        className="target-control"
        placeholder="Целевая аудитория"
      />
      <div className="form-control">
        <label>Qual.Reach%</label>
        <div className="qual-reach">
          <span>Группа 1 &gt;</span>
          <input type="text" id="g0" value={qualReach.g0}
                onChange={(e) => onGroupValueChange({...qualReach, g0: e.target.value})} />

          <span>Группа 2 &gt;</span>
          <input type="text" id="g1" value={qualReach.g1}
                onChange={(e) => onGroupValueChange({...qualReach, g1: e.target.value})} />

          <span>Группа 3 &gt;</span>
          <input type="text" id="g2" value={qualReach.g2}
                onChange={(e) => onGroupValueChange({...qualReach, g2: e.target.value})} />
        </div>
      </div>
      <div className="compare-audience-events">
        <div>
          <EventsAudience onChange={payload => onChange(1, payload)} defaultValue={payloadX} ref={payloadXRef} />
        </div>
        <div>
          <EventsAudience onChange={payload => onChange(2, payload)} defaultValue={payloadY} ref={payloadYRef} />
        </div>
      </div>

      <div className="compare-audience-method">
        <div className="compare-audience-method-title">Метод сравнения</div>
        <Radio.Group
          options={methodOptions}
          onChange={({ target: { value } }) => onMethodChange(value)}
          value={method}
        />
      </div>
    </>
  )
}

export default CompareAudienceFilters;
