import React, { useState, useMemo, useRef, useCallback } from 'react';
import API from '@utils/AdminApi';
import Common from '@utils/common';
import DemographicsFilters from './DemographicsFilters';
import { Button } from '@components/UI/Buttons';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setPie, setChannelId, setPath, setPayload, setFilters } from '@store/audiencePanel';
import ExportButton from '@UI/ExportButton/ExportButton';
import Csv from '@utils/Csv';
import * as XLSX from 'xlsx/xlsx.mjs';
import { toast } from 'react-toastify';

import { ReactComponent as Arrow } from '../../../assets/arrow.svg';
import { Radio } from 'antd';


function Demographics({ members }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [ isLoading, setIsLoading ] = useState(false);

  const { queryData, pie, payload, path } = useSelector((state) => state.audiencePanel);

  const highchartsRef = useRef();

  const charts = useMemo(() => {
    const pieData = JSON.parse(JSON.stringify(pie));

    return {
      exporting: {
        fallbackToExportServer: false,
        buttons: {
          contextButton: {
            enabled: false
          },
          printButton: {
              enabled: false
          },
          exportButton: {
              enabled: false
          }, 
        }
      },
      chart: {
        height: 500,
      },
      title: {
        text: null,
      },
      xAxis: [
        {
          categories: ['Channels'],
          labels: {
            enabled: true,
          },
        },
      ],
      plotOptions: {
        pie: {
          events: {
            click: (e) => {
              switch (e.point.custom.data_type) {
                case 'categories':
                  showCategories(e.point.custom);
                  break
                case 'programs':
                  showPrograms(e.point.custom);
                  break
                case 'program':
                  goToProgram(e.point.custom);
                  break
                default:
                  break
              }
            }
          },
          center: ["50%", "50%"],
          innerSize: 140,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.percentage:.0f}%',
          },
        }
      },
      credits: {
        text: 'tvmetrica.ru'
      },
      series: [ path === 'channels' ? pieData.channels : (path === 'categories' ? pieData.categories : pieData.programs) ],
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
        // pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>'
      },
      yAxis: {
        visible: false,
      },
    }
  }, [ path, pie ]);

  const submitForm = async () => {
    setIsLoading(true);

    const from = Common.pickers2TVDatetime(payload.period.startDate, queryData.fromTime);
    const to = Common.pickers2TVDatetime(payload.period.endDate, queryData.toTime);

    let filters = {
      from,
      to,
      payload: {
        sample: location.state.sample,
        method: method,
        timeband: null,
        premierOption: payload.premier,
      }
    };

    if (payload.timeband) {
      try {
        const timeband = await API.getCollection(payload.timeband.id);
        filters.payload.timeband = timeband.data;
      } catch {

      }
    }
    dispatch(setFilters(filters));

    showChannels(filters);
  }

  const showChannels = (filters) => {
    const payload = Common.copy(filters.payload);
    payload['dates_range'] = {'start': filters.from, 'end': filters.to}
    API.postAudienceWatchedChannels(payload).then(data => {
      dispatch(setPath('channels'));
      dispatch(setPie({data_type: 'channels', value: { ...data, type: 'pie',}}));
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const showCategories = (data) => {
    setIsLoading(true);
    // API.postAudienceWatchedCategories(filters.from, filters.to, filters.payload, channelId).then((result) => {
    dispatch(setPath('categories'));
    dispatch(setChannelId(data.channel_id));
    dispatch(setPie({ data_type: 'categories', value :{ ...data, type: 'pie' } }));
    // }).finally(() => {
    //   setIsLoading(false);
    // });
    setIsLoading(false);
  }

  const showPrograms = (data) => {
    setIsLoading(true);
    // API.postAudienceWatchedPrograms(filters.from, filters.to, filters.payload, channelId, typeId).then((result) => {
      dispatch(setPath('programs'));
      dispatch(setPie({ data_type: 'programs', value: { ...data, type: 'pie' } }));
    // }).finally(() => {
      setIsLoading(false);
    // });
  }

  const goToProgram = (program) => {

    navigate(`/programs/${program.program_id}/channel/${program.channel_id}`, {
      state: location.state
    });
  }

  const onChange = (payload) => {
    dispatch(setPayload(payload));
  }

  const goBack = () => {
    let newPath = path === 'programs' ? 'categories' : (path === 'categories' ? 'channels' : '');
    dispatch(setPath(newPath));
  }

  const channelSelected = useMemo(() => path === 'channels' ? 'current' : '', [ path ]);
  const categorySelected = useMemo(() => path === 'categories' ? 'current' : '', [ path ]);
  const programSelected = useMemo(() => path === 'programs' ? 'current' : '', [ path ]);

  const [ method, setMethod ] = useState(location.state.method);

  const methodOptions = [
    {
      label: 'Факт',
      value: 'fact',
    },
    {
      label: 'Соцдем',
      value: 'demography',
    },
    {
      label: 'Профиль',
      value: 'profile',
    },
  ];
  
  const exportData = useCallback((seperator = ';') => {
    if (!path || !pie[path]) {
      return;
    }

    const title = [];

    title.push('Каналы');
    path !== 'channels' && title.push('Жанры');
    path === 'programs' && title.push('Программы');

    if (seperator == 'image') {
      highchartsRef.current.chart.exportChartLocal({}, {
        chart: {
          width: 1000
        }, 
        title: {
          text: title.join(' - '),
        },
      });

      return;
    }

    const chartData = pie[path];

    const rows = [];

    rows.push(seperator ? chartData.name : [chartData.name]);

    chartData.data.forEach(it => {
      const row = [
        it.name,
        it.y,
      ];

      rows.push(seperator ? row.join(seperator) : row);
    });
    
    if (seperator) {
      Csv(rows.join("\r\n"), true);
      toast.success(`Данные скопированы в буфер обмена`);
    } else {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(rows);

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Chart');

      XLSX.writeFile(workbook, `demographics-${path}.xls`);
    }
  }, [ pie, path ]);

  return (
    <div className="audience-wrapper">

      <div className="audience-title">
        <div className="title">
          <div>Смотрение за интервал</div>
        </div>
        <div className="audience-method">
          <Radio.Group
            options={methodOptions}
            onChange={({target: {value}}) => setMethod(value)}
            value={method}
          />
        </div>
        <div className="title-button">
          <Button
            onClick={() => submitForm()}
            disabled={isLoading}
            type="primary"
            label="Показать"
          />
        </div>
      </div>

      <div className="audience-content">
        <DemographicsFilters onChange={payload => onChange(payload)} defaultValue={payload} queryData={queryData}/>
        {!!path && <div className="path">
          <span className={channelSelected} onClick={() => dispatch(setPath('channels'))}>Каналы</span>
          { path !== 'channels' && <><span className="arrow"><Arrow /></span><span className={categorySelected} onClick={() => dispatch(setPath('categories'))}>Жанры</span></> }
          { path === 'programs' && <><span className="arrow"><Arrow /></span><span className={programSelected} onClick={() => dispatch(setPath('programs'))}>Программы</span></> }
        </div> }
        <div className="bars pipes">
          <ExportButton onClick={sep => exportData(sep)} />
          <HighchartsReact highcharts={Highcharts} options={charts} ref={highchartsRef} allowChartUpdate={true} showLoading={true}/>
          { path && path !== 'channels' && <div className="back-button" onClick={goBack}>Назад</div> }
          { isLoading && <div className="is-loading">Loading...</div> }
        </div>
      </div>
    </div>
  );
}

export default Demographics;
