import axios from 'axios';
import { toast } from 'react-toastify';

// eslint-disable-next-line import/no-cycle
import Common from '@utils/common';

import { ADMIN_HOST, API_HOST } from './Env';

export const admin_instance = axios.create({
  baseURL: ADMIN_HOST,
});

export const api_instance = axios.create({
  baseURL: API_HOST,
});

const API = {
  population100k: null as boolean | null,
  getUrl(url) {
    return admin_instance
      .get(url)
      .then((res) => res.data)
      .catch((error) => toast.error(error));
  },
  setToken(token) {
    if (token) {
      admin_instance.defaults.headers.common.authorization = `Bearer ${token}`;
    } else {
      delete admin_instance.defaults.headers.common.authorization;
    }
  },
  drilldown(params) {
    const p = convertObjectToUrlParams(params);

    return api_instance
      .get(`/api/drilldown?${p}`)
      .then((res) => res.data)
      .catch((error) => toast.error(error));
  },
  bytime(params) {
    const p = convertObjectToUrlParams(params);

    return api_instance.get(`/api/bytime?${p}`).then((res) => res.data);
  },
  bytimeExcelExport(params) {
    const p = convertObjectToUrlParams(params);

    return api_instance
      .get(`/api/bytime?${p}`, {
        responseType: 'blob',
      })
      .then((res) => res.data)
      .catch((error) => toast.error(error));
  },
  filter() {
    return api_instance
      .get('api/filter')
      .then((res) => res.data)
      .catch((error) => toast.error(error));
  },
  /** AUTH */
  login(username, password) {
    return admin_instance.post('/user/login', { username, password });
  },
  logout() {
    return admin_instance.get('/user/logout');
  },
  userInfo() {
    return admin_instance.get('/user/info');
  },
  resetPassword(email) {
    return admin_instance.post('/user/reset', { email });
  },
  changePassword(oldPassword, newPassword) {
    return admin_instance.patch('/user/password', { password: newPassword });
  },
  /** USER PRESETS */
  getGraphPresets() {
    return admin_instance.get('/user/graph');
  },
  getGraphPreset(id) {
    return admin_instance.get(`/user/graph/${id}`);
  },
  saveGraphPreset(name, content) {
    return admin_instance.post('/user/graph', {
      name,
      content,
    });
  },
  updateGraphPreset(id, name, content) {
    return admin_instance.patch(
      `/user/graph/${id}`,
      content !== null
        ? {
            name,
            content,
          }
        : { name },
    );
  },
  deleteGraphPreset(id) {
    return admin_instance.delete(`/user/graph/${id}`);
  },

  /** Collections */
  getCollectiontypes() {
    return admin_instance
      .get('/site/collections/types/')
      .then((response) => response.data);
  },
  getCollections(shared = true) {
    return admin_instance.get('/site/collections/' + (shared ? '' : '?shared=false'));
  },
  getCollection(id) {
    return admin_instance.get(`/site/collections/${id}`);
  },
  getCollectionShared(id) {
    return admin_instance.get(`/site/collections/${id}/shared/`);
  },
  updateCollectionShared(id, payload) {
    return admin_instance
      .post(`/site/collections/${id}/shared/`, payload);
  },
  saveCollection({ name, type, parent, content }) {
    return admin_instance
      .post('/site/collections/', {
        name,
        type,
        parent,
        content,
      })
      .then((response) => response?.data?.id);
  },
  updateCollection({ id, name, type, parent, content, is_default }) {
    return admin_instance
      .patch(`/site/collections/${id}/`, {
        name,
        type,
        parent,
        content,
        is_default
      })
      .then((response) => {
        return response?.data?.id;
      });
  },
  changeGroup({ id, parent }) {
    return admin_instance
      .patch(`/site/collections/${id}/`, {
        parent,
      })
      .then((response) => {
        return response?.data?.id;
      });
  },
  deleteCollection(id, isGroup) {
    return admin_instance.delete(
      `/site/collections/${id}/${isGroup ? '?is_group=1' : ''}`,
    );
  },
  saveGroup({ parent, name, type }) {
    return admin_instance
      .post('/site/collections/', {
        parent,
        name,
        is_group: true,
        type,
      })
      .then((response) => response?.data?.id);
  },
  updateGroup({ id, parent, name, type }) {
    return admin_instance
      .patch(`/site/collections/${id}/`, {
        parent,
        name,
        is_group: true,
        type,
      })
      .then((response) => response?.data?.id);
  },
  getChannelsCollections() {
    return admin_instance.get('/site/collections/type/2/');
  },
  getConditionOptions(id) {
    return api_instance
      .get(`/api/v3/adverts/filters/${id}`)
      .then((response) => response.data);
  },
  getAdvertsColumns() {
    return api_instance
    .get('/api/v3/adverts/columns')
    .then((response) => response.data);
  },
  getAudienceSample(date, collection) {
    return api_instance
      .post('/api/v3/panel/audience/sample', { date, collection, population_100: this.population100k })
      .then((response) => response.data);
  },

  /** Average  */
  postAverage(payload) {
    return api_instance.post('/api/v3/panel/average', payload);
  },

  getAdvertsArticles() {
    return api_instance.get('/api/v3/adverts/articles').then(response => response.data);
  },
  getAdvertsModel(id) {
    return api_instance.get(`/api/v3/adverts/model/${id}`).then(response => response.data);
  },
  searchAdvertsArticles(search) {
    return api_instance.get(`/api/v3/adverts/articles/search/${search}`).then(response => response.data);
  },
  getAdvertsBrands() {
    return api_instance.get('/api/v3/adverts/brands').then(response => response.data);
  },
  searchAdvertsBrands(search) {
    return api_instance.get(`/api/v3/adverts/brand/search/${search}`).then(response => response.data);
  },
  getAdvertsTree(id) {
    return api_instance.get(`/api/v3/adverts/tree/${id}`).then(response => response.data);
  },
  getAdvertsTreePlus(id) {
    return api_instance.get(`/api/v3/adverts/tree_plus/${id}`).then(response => response.data);
  },
  searchAdvertsSubbrands(search) {
    return api_instance.get(`/api/v3/adverts/subbrand/search/${search}`).then(response => response.data);
  },
  searchAdverts(search) {
    return api_instance.get(`/api/v3/adverts/search/${search}`).then(response => response.data);
  },
  searchAdvertsAdvert(search) {
    return api_instance.get(`/api/v3/adverts/advert/search/${search}`).then(response => response.data);
  },
  searchAdvertsAdvertiser(search) {
    return api_instance.get(`/api/v3/adverts/advertiser/search/${search}`).then(response => response.data);
  },
  searchAdvertsModel(search) {
    return api_instance.get(`/api/v3/adverts/model/search/${search}`).then(response => response.data);
  },


  searchAdvertsAdvertiserlist(search) {
    return api_instance.get(`/api/v3/adverts/advertiserlist/search/${search}`).then(response => response.data);
  },
  searchAdvertsBrandlist(search) {
    return api_instance.get(`/api/v3/adverts/brandlist/search/${search}`).then(response => response.data);
  },
  searchAdvertsSubbrandlist(search) {
    return api_instance.get(`/api/v3/adverts/subbrandlist/search/${search}`).then(response => response.data);
  },
  postAdverts(payload) {
    return api_instance
      .post('/api/v3/adverts/statistics', { ...payload, population_100: this.population100k })
      .then((response) => response.data);
  },
  /** Panel */
  postPanel({ datesRange }) {
    return api_instance.post('/api/v3/panel/', {
      startDate: Common.toTVDate(datesRange.startDate, true),
      endDate: Common.toTVDate(datesRange.endDate, true),
    });
  },
  postMemberInfo({ member_id, datesRange }) {
    return api_instance.post(`/api/v3/panel/member/${member_id}/`, {
      startDate: Common.toTVDate(datesRange.startDate, true),
      endDate: Common.toTVDate(datesRange.endDate, true),
    });
  },
  postViewHistory({ member_id, datesRange }) {
    return api_instance.post(
      `/api/v3/panel/member/${member_id}/view_history/`,
      {
        startDate: Common.toTVDate(datesRange.startDate, true),
        endDate: Common.toTVDate(datesRange.endDate, true),
      },
    );
  },
  postMemberCoreHours({ member_id, datesRange, tab_no }) {
    return api_instance.post(
      `/api/v3/panel/member/${member_id}/core_hours/${tab_no}/`,
      {
        startDate: Common.toTVDate(datesRange.startDate, true),
        endDate: Common.toTVDate(datesRange.endDate, true),
      },
    );
  },
  postMemberFavouriteChannels({ member_id, datesRange, range }) {
    return api_instance.post(
      `/api/v3/panel/member/${member_id}/favourite_channels/${range}/`,
      {
        startDate: Common.toTVDate(datesRange.startDate, true),
        endDate: Common.toTVDate(datesRange.endDate, true),
      },
    );
  },
  postMemberFavouritePrograms({ member_id, datesRange, range }) {
    return api_instance.post(
      `/api/v3/panel/member/${member_id}/favourite_programs/${range}/`,
      {
        startDate: Common.toTVDate(datesRange.startDate, true),
        endDate: Common.toTVDate(datesRange.endDate, true),
      },
    );
  },
  postHouseholdInfo({ household_id, datesRange }) {
    return api_instance.post(`/api/v3/panel/household/${household_id}/`, {
      startDate: Common.toTVDate(datesRange.startDate, true),
      endDate: Common.toTVDate(datesRange.endDate, true),
    });
  },
  postCityInfo({ city_id, datesRange }) {
    return api_instance.post(`/api/v3/panel/city/${city_id}/`, {
      startDate: Common.toTVDate(datesRange.startDate, true),
      endDate: Common.toTVDate(datesRange.endDate, true),
    });
  },
  postRegionInfo({ region_id, datesRange }) {
    return api_instance.post(`/api/v3/panel/region/${region_id}/`, {
      startDate: Common.toTVDate(datesRange.startDate, true),
      endDate: Common.toTVDate(datesRange.endDate, true),
    });
  },
  postCompare(payload) {
    return api_instance.post('/api/v3/panel/compare', { ...payload });
  },
  postAudienceDemographics(source, sample, demographics) {
    return api_instance
      .post('/api/v3/panel/audience/demographics', {
        source,
        sample,
        demographics,
      })
      .then((response) => response.data);
  },
  postAudienceWatchedChannels(payload) {
    return api_instance
      .post(
        `/api/v3/panel/audience/watched/`,
        payload,
      )
      .then((response) => response.data);
  },
  postAudienceWatchedCategories(datetimeFrom, datetimeTo, payload, channelId) {
    return api_instance
      .post(
        `/api/v3/panel/audience/watched/${datetimeFrom}/${datetimeTo}/${channelId}`,
        payload,
      )
      .then((response) => response.data);
  },
  postAudienceWatchedPrograms(
    datetimeFrom,
    datetimeTo,
    payload,
    channelId,
    typeId,
  ) {
    return api_instance
      .post(
        `/api/v3/panel/audience/watched/${datetimeFrom}/${datetimeTo}/${channelId}/${typeId}`,
        payload,
      )
      .then((response) => response.data);
  },

  /** Programs */
  getProgramsChannels() {
    return api_instance
      .get('/api/v3/glossary/channels')
      .then((response) => response.data);
  },
  getCategoriesByChannelAndType(channel_id, type_id) {
    return api_instance
      .get(`/api/v3/programs/channel/${channel_id}/type/${type_id}/categories/`)
      .then((response) => response.data);
  },
  getProgramsByChannelAndCategory(channel_id, category_id) {
    return api_instance
      .get(`/api/v3/programs/channel/${channel_id}/category/${category_id}/`)
      .then((response) => response.data);
  },
  getProgramsTypesByChannel(channel_id) {
    return api_instance
      .get(`/api/v3/programs/channel/${channel_id}/types/`)
      .then((response) => response.data);
  },
  getProgramInfo(channel_id, program_id) {
    return api_instance
      .get(`/api/v3/programs/${program_id}/channel/${channel_id}/`)
      .then((response) => response.data);
  },
  getProgramIssuedInfo(issued_id) {
    return api_instance
      .get(`/api/v3/programs/issued/${issued_id}/`)
      .then((response) => response.data);
  },
  getProgramIssuedDates(issued_path) {
    return api_instance
      .get(`/api/v3/programs/${issued_path}/issued_dates`)
      .then((response) => response.data);
  },
  getProgramIssuedMultiselect(program_id) {
    return api_instance
      .get(`/api/v3/programs/${program_id}/issued/?multiselect=1`)
      .then((response) => response.data);
  },
  getProgramIssuedPicker(program_id) {
    return api_instance
      .get(`/api/v3/programs/${program_id}/issued_picker`)
      .then((response) => response.data);
  },
  getProgramCategories() {
    return api_instance
      .get('/api/v3/programs/categories/')
      .then((response) => response.data);
  },
  getProgramIssued(program_id, limit, offset, sort = 'desc') {
    return api_instance
      .get(
        `/api/v3/programs/${program_id}/issued/?limit=${limit}&offset=${offset}&sort=${sort}`,
      )
      .then((response) => response.data);
  },
  getProgramIssuedByIds(program_id, ids) {
    return api_instance
      .get(`/api/v3/programs/${program_id}/issued/?issued_ids=${ids.join(',')}`)
      .then((response) => response.data);
  },
  getProgramIssuedByPeriod(
    programId,
    channelId,
    startDate,
    endDate,
    timeband,
    premier,
  ) {
    return api_instance
      .post(`/api/v3/programs/${programId}/issued/${channelId}/`, {
        dates_range: {
          start: startDate,
          end: endDate
        },
        timeband,
        premier,
      })
      .then((response) => response.data);
  },
  postProgramIssuedStatistics(issued_id, statistic, target_base) {
    const payload = {
      events: {
        issued_ids: [issued_id, ],
        split_by: 1
      },
      statistics: statistic,
      target_base: target_base,
    };

    return api_instance
      .post(`/api/v3/programs/issued/${issued_id}/statistics`, { ...payload, population_100: this.population100k })
      .then((response) => response.data);
  },
  getProgramIssuedGainLost(issued_id, viewtime) {
    return api_instance
      .get(`/api/v3/programs/issued/${issued_id}/gainlost/${viewtime}/`)
      .then((response) => response.data);
  },
  getSearchPrograms(text) {
    return api_instance
      .get(`/api/v3/programs/search/${text}?plain=true`)
      .then((response) => response.data);
  },
  getChannelIssuedPrograms(channel_id, date, statistics) {
    // eslint-disable-next-line no-param-reassign
    statistics = statistics ? `?statistics=${statistics}` : '';

    return api_instance
      .get(`/api/v3/channel/${channel_id}/programs/${date}${statistics}`)
      .then((response) => response.data);
  },
  getChannelAudience(channel_id, dt_start, dt_finish) {
    return api_instance
      .get(`/api/v3/channel/${channel_id}/audience/${dt_start}/${dt_finish}`)
      .then((response) => response.data);
  },
  getChannelMoves(channel_id, dt_start, position, base, interval) {
    const payload = {
      dt_start: dt_start,
      position: position,
      target_base: base,
      interval,
    };

    return api_instance
      .post(`/api/v3/channel/${channel_id}/moves`, { ...payload, population_100: this.population100k })
      .then((response) => response.data);
  },

  /** Glossary */
  getDemographics(filter = null) {
    return api_instance
      .get(
        '/api/v3/glossary/demographics' +
          (filter !== null ? `?filter=${filter}` : ''),
      )
      .then((response) => response.data);
  },
  getChannels() {
    return api_instance
      .get('/api/v3/glossary/channels')
      .then((response) => response.data);
  },
  getStatistics(source: string | null) {
    return api_instance
      .get(`/api/v3/glossary/statistics${source ? '?source=' + source : ''}`)
      .then((response) => response.data);
  },
  getChannelsStatistics(payload) {
    return api_instance
      .post('/api/v3/channel', payload)
      .then((response) => response.data);
  },
  getDataset(payload) {
    return api_instance
      .post('/api/v3/panel/custom_report', payload)
      .then((response) => response.data);
  },
  getProgramsOptions(id = null) {
    return api_instance
      .get('/api/v3/programs/tree' + (id !== null ? `/${id}` : ''))
      .then((response) => response.data);
  },
  searchProgramsOptions(text) {
    return api_instance
      .get(`/api/v3/programs/search/${text}`)
      .then((response) => response.data);
  },
  /** status */
  getStatus() {
    return api_instance.get('/api/v3/status').then((response) => response.data);
  },

  /** schedule */
  getBroadcastData(payload) {
    return api_instance
      .post('/api/v3/channel/broadcast', { ...payload, population_100: this.population100k })
      .then((response) => response.data);
  },
  getDownloadLink(filename) {
    return `${API_HOST}/api/v3/download/${filename}`;
  },
};

export default API;

/**
 * @param params {object}
 * @returns {string}
 */
function convertObjectToUrlParams(
  params: Record<string, any>,
): string | undefined {
  try {
    let paramsString = '';

    // todo KTS fix typings
    for (const [key, item] of Object.entries(params)) {
      const param = encodeURIComponent(key);

      const value = encodeURIComponent(item);

      paramsString += `${param}=${value}&`;
    }

    // remove last &
    return paramsString.slice(0, -1);
  } catch (e) {
    // todo KTS check if we should return ''; in an error happens
    // eslint-disable-next-line no-console
    console.log(e);
  }
}
